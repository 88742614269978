import { makeStyles } from '@material-ui/core'
import { useContext } from 'react'
import { AppContext } from '../App'

const useStyles = makeStyles((theme) => ({
  footer: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    backgroundColor: theme.palette.common.black,
    height: '200px',
    display: 'flex',
    '& .two': {
      width: '100%',
      backgroundColor: (p: { bgColor?: string }) => (p.bgColor ? p.bgColor : '#F2F2F2'),
      clipPath: 'ellipse(52% 90% at 50% -19%)',
    },
  },
  footerLink: {
    backgroundColor: theme.palette.common.black,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
}))

export const AMFooter = () => {
  const { config } = useContext(AppContext)
  const classes = useStyles({ bgColor: config?.backgroundColor })

  return (
    <div>
      <div className={classes.footer}>
        <div className={'two'}></div>
      </div>
            <div className={classes.footerLink}>
            <a href="https://www.cp.com/en/privacy-policy" >
              Privacy Policy
            </a>
          </div>
    </div>
  )
}
